@import 'assets/styles/constants.scss';

.with-padding {
    padding-top: 1rem;
}

.expandable-col > div > div {
    padding-left: 0 !important;
}

.bottom-border {
    border-bottom: 1px solid $color-background-control-disabled !important;
}
