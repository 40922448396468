

.channel-allocation-modal {
    padding: 0.5em;
}
.channel-allocation-row {
    display: flex;
    text-align: center;
}
.channel-allocation-cell {
    padding: 0.5em;
    width: 16rem !important;
}

.invalid-allocation {
    padding: 0.5em;
    width: 16rem !important;
}
