
.channel-allocation-row {
    display: flex;
    text-align: center;
}
.channel-allocation-cell {
    padding: 0.5em;
    width: 16rem !important;
}

.channel-allocation-input-cell {
    width: 100px !important;
    margin: auto;
}
