.table-row {
    display: flex;
    text-align: left;
}

.table-cell {
    padding-bottom: 1em;
    padding-top: 1em;
    padding-left: 0.5em;
    text-align: left;
    width: 25rem !important;
}

.dropdown-row {
    padding-top: 0.5em;
}

.dropdown-label-cell {
    padding-left: 0.5em;
    text-align: left;
    width: 10rem !important;
    color: gray;
}

.dropdown-amount-cell {
    padding-right: 0.5em;
    text-align: right;
    width: 15rem !important;
    color: gray;
}

.table-cell-amount {
    padding-bottom: 1em;
    padding-top: 1em;
    padding-left: 0.5em;
    text-align: right;
    width: 20rem !important;
}

.table-button {
    padding-bottom: 1em;
    padding-top: 1em;
    padding-left: 1.5em;
    width: 20rem !important;
}
