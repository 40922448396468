@use "sass:string";
@import '@amzn/awsui-global-styles/polaris.css';
@import './constants.scss';


@mixin box-em-1($style) {
    $sides: 'left', 'top', 'right', 'bottom';
    $stylePrefix: str-slice($style, 1, 1);

    @each $side in $sides {
        $sideSufix: str-slice($side, 1, 1);

        @for $i from 0 through 5 {
            .#{$stylePrefix}#{$sideSufix}-#{$i} {
                #{$style}-#{$side}: #{$i}em;
            }
        }

        .#{$stylePrefix}#{$sideSufix}-auto {
            #{$style}-#{$side}: auto;
        }
    }
}

@include box-em-1('margin');
@include box-em-1('padding');

.has-display-flex {
    display: flex;

    &.with-columns {
        flex-direction: column;
    }

    &.with-rows {
        flex-direction: row;
    }

    &.is-vertically-centered {
        align-items: center;
    }

    &.has-space-between {
        justify-content: space-between;
    }
}

.is-full-height {
    height: 100%;
}

button.awsui-util-help-info-link {
    background-color: transparent;
    border: none;
    color: $color-text-status-info;
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.01em;
    margin-left: 1rem;

    &:focus {
        outline: none;
    }
    .has-black-text {
        color: black;
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-10px);
    }
    100% {
        transform: translatey(0px);
    }
}

.logo-loading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50vh;

    .logo {
        text-align: center;

        svg {
            height: 15vh;
            animation: float 2s ease-in-out infinite;
        }
    }
}

.msp-expandable-table {
    td {
        padding: 0 !important;
    }

    td:first-child {
        awsui-checkbox {
            padding-left: 1.9rem !important;
        }
    }

    th span {
        overflow: visible !important;
    }
}
