@import 'assets/styles/constants.scss';

.expandable-row {
    line-height: 2em;
    min-height: 2em;
}

.control-btn {
    margin-left: -0.7em;
}

.container {
    .cell {
        @extend .expandable-row;

        display: flex;
        border: 1px solid transparent;
        box-sizing: border-box;
        word-wrap: break-word;

        .span {
            margin-top: 50%;
        }
    }

    .content-row {
        @extend .expandable-row;
        background-color: $color-text-notification-severity-high;
        padding-left: 1em;
    }

    .content-row-control {
        @extend .content-row;
        padding-left: 1em;
    }

    &.selected {
        background-color: $color-background-item-selected;

        > .cell {
            background-color: $color-background-item-selected
        }
    }
}

