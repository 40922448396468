@import 'assets/styles/constants.scss';

.audit-view-container {
    margin: 1rem -30% 1rem 1rem;
}

.audit-title {
    color: #3a3b68 !important;
}

.audit-dropdown {
    width: 10%;
    padding: 1rem;
}

.audit-loading {
    text-align: center;
}

.audit-timeline {
    display: flex;
    height: 100vh;

    &.vertical {
        flex-direction: column;
    }
}

.audit-event {
    display: flex;
}

.audit-line-left {
    border-left: solid 1px black;
    position: relative;
}

.dot {
    width: 0.95rem;
    height: 0.95rem;
    background-color: black;
    border-radius: 100%;
    right: calc(-0.847 * (0.95rem / 2));
    top: 4.5rem;
    position: absolute;
}

.confirmed-dot {
    width: 0.95rem;
    height: 0.95rem;
    background-color: green;
    border-radius: 100%;
    right: calc(-0.847 * (0.95rem / 2));
    top: 4.5rem;
    position: absolute;
}

.audit-version-date {
    color: black;
    width: 11rem;
    margin: 2rem;
    text-align: right;
}

.audit-version-container {
    margin: 2rem;
    padding-left: 2rem;
    width: 100%;
}
