.dropdown-div-coa {
    padding-right: 1rem !important;
    padding-bottom: 1rem !important;
    width: 12rem !important;
}

.dropdown-div-attributes {
    padding-right: 1rem !important;
    padding-bottom: 1rem !important;
    width: 30rem !important;
}

.table-header-div {
    padding-top: 1rem !important;
}

.modal-div {
    padding: 1rem !important;
}

.div-coa {
    width: 12rem !important;
}

.div-attributes {
    width: 30rem !important;
}
