@import 'assets/styles/constants.scss';

.full_screen_container {
    background: $color-background-control-disabled;
    position: fixed;
    top: 0;
    left: 0;

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
}

.txt {
    text-align: center;
    font-weight: bold;
}

.img {
    text-align: center;
    &.orange {
        color: $color-background-button-primary-hover;
    }
    &.red {
        color: $color-charts-red-600;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 130px;
}
