@import '@amzn/awsui-design-tokens/polaris.scss';

.input {
    width: 50% !important;
    float: left !important;
}

.modal-div {
    padding: 1rem !important;
}

.org-updates-div {
    padding: 1.5rem !important;
}

.div-right-padding {
    padding-right: 1.5rem !important;
}

.grey-text {
    color: $color-charts-status-neutral!important;
    padding-bottom: 1.5rem !important;
}
