.awsui-util-container-no-gutters {
    display: flex;
    flex-direction: column;
}

#user {
    display: flex;
    padding: 1rem;
}

#item {
    padding: 1rem;
}

#selectmenu {
    width: 10%;
}
