@import 'assets/styles/constants.scss';

.unconfirmed,
.updateneeded {
    color: $color-charts-red-600;
}

.confirmed,
.open {
    color: $color-charts-threshold-positive;
}

.closed,
.softclosed,
.cancelled {
    color: $color-text-dropdown-item-disabled;
}
