@import 'assets/styles/constants.scss';

.pending {
    color: $color-background-button-primary-hover;
}

.completed {
    color: $color-charts-threshold-positive;
}

.canceled,
.notAvailable {
    color: $color-text-input-disabled
}
