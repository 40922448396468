

.content-padding {
    padding-bottom: 20px !important;
}

.line-content-padding {
    padding-left: 50px !important;
    width: 100%;
}

.amount-content-padding {
    padding-right: 5em !important;
}
