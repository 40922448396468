@import 'assets/styles/constants.scss';

.invoice-section {
    box-shadow: none !important;

    div {
        box-shadow: none !important;

        div:nth-child(2) {
            padding: 0 !important;
        }
    }
}
