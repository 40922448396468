@import 'assets/styles/constants.scss';

.closed,
.softclosed {
    color: $color-text-input-placeholder;
}

.property-filtering-0 {
    margin-bottom: 4rem !important;
}

.property-filtering {
    margin-bottom: 8rem !important;
}

.input-width {
    margin-top: 1rem !important;
    width: 70rem !important;
    float: left !important;
}

.search-button {
    margin-top: 1rem !important;
    padding-left: 1rem !important;
}
