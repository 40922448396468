@import 'assets/styles/constants.scss';

.with-padding {
    padding-left: 3.6rem;
}

.title-left-padding {
    padding-left: 4.4rem !important;
}

.invoice-table {
    table {
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        text-align: left;
        padding: 8px;
    }

    td {
        width: 15%;
    }

    .is-white {
        background-color: $color-background-input-default !important;
    }

    .is-grey {
        background-color: $color-background-input-disabled !important;
    }

    .bottom-border {
        border-bottom: 1px solid $color-background-control-disabled !important;
    }

    table tr td:nth-child(2) {
        border-right: 1px solid $color-background-input-default;
    }

    table tr th:nth-child(2) {
        border-right: 1px solid $color-background-input-default;
    }

    table tr td:nth-child(5) {
        border-right: 1px solid $color-background-input-default;
    }

    table tr th:nth-child(5) {
        border-right: 1px solid $color-background-input-default;
    }

    table tr td:last-of-type {
        border: none;
    }
}
