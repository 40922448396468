@import 'assets/styles/constants.scss';

.invoice-lines-container {
    margin-top: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-bottom: 0 !important;
}

.border {
    border-bottom: 1px solid $color-text-home-header-secondary;
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
}

.spinner-centered {
    text-align: center;
}

.grid-container {
    display: grid;
    column-gap: 50px;
    grid-template-columns: auto auto auto;
}

